import React from 'react';
import Layout from '../common/layouts';
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link } from 'gatsby';
import Seo from '../common/seo';
import { MDXRenderer } from 'gatsby-plugin-mdx'


export default ({props, data}) => (
  <Layout>
    <Seo
      title={`Terms of Service ${data.site.siteMetadata.title}`}
      description={data.mdx.frontmatter.title} />
    <div className="bg-lightest-blue near-black pv5 flex flex-column items-center">
      <h1 className="db f1 display fw1">TERMS OF SERVICE</h1>
    </div>
    <div className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f4">
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </div>
  </Layout>
)

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mdx(frontmatter: {name: {eq: "terms_of_service"}}) {
      body
      frontmatter {
        title
      }
    }
  }
`
